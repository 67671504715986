import Modal from '../common/Modal';
import DOM from '../helpers/Browser/DOM';
import { Control } from 'ol/control';
import { SVGPaths, getIcon } from '../core/Icons';
import { isShortcutKeyOnly } from '../helpers/ShortcutKeyOnly';

const DEFAULT_OPTIONS = {
    title: 'Hey!',
    content: 'This is the default content, try adding some content of your own.'
};

class Info extends Control {
    constructor(options = {}) {
        const element = document.getElementById(options.target ? options.target : 'oltb');
        const map = document.getElementById('ol-map');
        let toolbox = document.getElementById('ol-toolbox');
        if(toolbox === null) {
            toolbox = DOM.createElement({
                element: 'div',
                id: 'ol-toolbox',
                class: 'oltb-toolbox-container'
            });
        }
        super({
            element: element
        });
        
        const icon = getIcon({
            path: SVGPaths.Info,
            class: 'oltb-tool-button__icon'
        });

        const button = DOM.createElement({
            element: 'button',
            html: icon,
            class: 'oltb-tool-button',
            attributes: {
                type: 'button',
                'data-tippy-content': 'Info (I)'
            },
            listeners: {
                'click': this.handleClick.bind(this)
            }
        });

        this.element.appendChild(button);
        this.button = button
        this.options = { ...DEFAULT_OPTIONS, ...options };
        window.addEventListener('keyup', (event) => {
            if(isShortcutKeyOnly(event, 'i')) {
                this.handleClick(event);
            }
        });
    }

    handleClick() {
        this.active = !this.active
        this.button.classList.toggle('oltb-tool-button--active');
        if(typeof this.options.click === 'function') {
            this.options.click(this.active);
        }
    }
}

export default Info;