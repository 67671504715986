import DOM from '../helpers/Browser/DOM';
import { Control } from 'ol/control';
import { SVGPaths, getIcon } from '../core/Icons';
import { isShortcutKeyOnly } from '../helpers/ShortcutKeyOnly';

class Refresh extends Control {
    constructor(options = {}) {
        const element = document.getElementById(options.target ? options.target : 'oltb');
        const map = document.getElementById('ol-map');
        let toolbox = document.getElementById('ol-toolbox');
        if(toolbox === null) {
            toolbox = DOM.createElement({
                element: 'div',
                id: 'ol-toolbox',
                class: 'oltb-toolbox-container'
            });
        }

        super({
            element: element
        });
        
        const icon = getIcon({
            path: SVGPaths.Refresh,
            class: 'oltb-tool-button__icon'
        });

        const button = DOM.createElement({
            element: 'button',
            html: icon,
            class: 'oltb-tool-button',
            attributes: {
                type: 'button',
                'data-tippy-content': 'Refresh page (R)'
            },
            listeners: {
                'click': this.handleClick.bind(this)
            }
        });

        this.element.appendChild(button);
        this.options = {...options };

        window.addEventListener('keyup', (event) => {
            if(isShortcutKeyOnly(event, 'r')) {
                this.handleClick(event);
            }
        });
    }

    handleClick() {
        if(typeof this.options.click === 'function') {
            this.options.click();
        }
    }
}

export default Refresh;